import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HearingLinkItem as HearingLinkItemType } from 'types/HearingLink';
import { useHistory } from 'react-router-dom';
import { useStore, useCopyToClipboard } from 'hooks';
import { formatTime } from 'utility/helpers';
import DefaultAvatar from 'assets/images/ic_default_avatar.svg';
import getHearingWebclientPath from 'utility/getHearingWebclientPath';
import { routes } from 'utility/constants';
import { hearingLinkStatus } from 'utility/constants';
import { ConfirmModal } from 'views/components/compound';
import { Label as _Label } from 'views/components/primitive';
import { palette, bp } from 'entity/createTheme';
import styled from '@emotion/styled';
import { Tooltip, Avatar } from '@material-ui/core';
import NoImage from 'assets/images/no-thumbnail.jpg';
import { useOrganization } from 'organisms/useOrganization';

interface PropTypes {
  data: HearingLinkItemType;
  updateStatusHearingLink(
    id: number,
    status: string,
    isDetail?: boolean,
    isHearingSet?: boolean
  ): void;
}

const HearingLinkItem: FC<PropTypes> = ({ data, updateStatusHearingLink }) => {
  const History = useHistory();
  const { organizationId } = useOrganization();
  const { t } = useTranslation();
  const {
    appStore: { isAdmin },
  } = useStore();
  const { archived } = useMemo(() => hearingLinkStatus, []);
  const { created_by: createdBy, updated_at, thumbnail_url } = useMemo(() => data, []);
  const [isModal, setIsModal] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const linkDetailURL = useMemo(() => {
    const hearingWebclientPath = getHearingWebclientPath(organizationId);
    return `${hearingWebclientPath}${routes.clientStartHearingLink}/${data.hash_token}`;
  }, [data.hash_token, organizationId]);

  const { handleCopy } = useCopyToClipboard(linkDetailURL);

  function toggleModal() {
    setIsModal(!isModal);
  }

  function handleClickLinkItem() {
    History.push(`${routes.hearingLink}/${data.id}`);
  }

  function handleArchive() {
    updateStatusHearingLink(data.id, archived);
  }

  function handleOnMouseEnter() {
    setIsHover(true);
  }

  function handleOnMouseLeave() {
    setIsHover(false);
  }

  return (
    <LinkWrapper isAdmin={isAdmin} onClick={handleClickLinkItem}>
      <Thumbnail src={thumbnail_url || NoImage} alt="thumbnail" width="100%" height="100%" />
      <ClientNameAndTitleWrapper>
        <TextWrapper>
          <p data-autify_selector="links_title">
            {data.administrative_title || t('sharingLink.noAdministrativeTitle')}
          </p>
          <ClientName>{data.client?.name ?? t('sharingLink.noSpecifiedAccount')}</ClientName>
        </TextWrapper>
        <MetaInfo>
          <UpdatedTime>
            {t('common.lastUpdated')}: {formatTime(updated_at, 'yyyy/MM/dd')}
          </UpdatedTime>
          <Label size="sm" variant="grey">
            {data.content_type === 'hearing_set'
              ? t('sharingLink.selectType.questionnaire.label')
              : data.content_type === 'product_contents'
              ? t('sharingLink.selectType.productContents.label')
              : t('sharingLink.selectType.playlist.label')}
          </Label>
        </MetaInfo>
      </ClientNameAndTitleWrapper>
      <LinkContainer
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        onClick={handleCopy}
      >
        <LinkContainerText>
          <LinkTitle>{isHover ? `${t('sharingLink.copyDefault')}` : `${linkDetailURL}`}</LinkTitle>
        </LinkContainerText>
      </LinkContainer>
      {isAdmin && (
        <Tooltip title={createdBy.username} placement="bottom-end">
          <AvatarWrapper>
            <StyledAvatar
              alt={createdBy.username}
              src={
                createdBy && data.created_by.profile_image_url
                  ? createdBy.profile_image_url
                  : DefaultAvatar
              }
            />
          </AvatarWrapper>
        </Tooltip>
      )}
      <ConfirmModal
        isModal={isModal}
        toggleModal={toggleModal}
        isArchive
        handleConfirmModal={handleArchive}
        item={t('common.actionTarget.links')}
      />
    </LinkWrapper>
  );
};

export default HearingLinkItem;

const LinkWrapper = styled('div')<{ isAdmin: boolean }>(props => ({
  display: 'grid',
  gridGap: 32,
  paddingRight: 16,
  gridTemplateColumns: props.isAdmin ? '142px 3fr 3fr 1fr' : '142px 3fr 3fr 1fr',
  background: palette.whitePrimary,
  borderRadius: 6,
  alignItems: 'center',
  cursor: 'pointer',
  fontSize: '0.875rem',
  boxShadow: `${palette.boxShadow} 0px 1px 9px`,

  '&:hover': {
    background: palette.whiteBlur,
  },

  '& >*': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  [`@media ${bp.md}`]: {
    gridGap: 8,
    gridTemplateColumns: props.isAdmin ? '142px 2fr 3fr 1fr' : '142px 3fr 2fr',
  },
}));

const ClientNameAndTitleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minWidth: 200,
  [`@media ${bp.md}`]: {
    margin: '0px 16px',
    minWidth: 132,
  },
});

const TextWrapper = styled('div')({
  flexGrow: 1,
  overflow: 'hidden',
  p: {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

const LinkContainerText = styled('div')({
  fontSize: '0.65rem',
  backgroundColor: palette.grayBlur4,
  borderRadius: '1rem',
  padding: '0.5rem',
  width: '100%',
  '&:hover': {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: palette.grayPrimary,
    backgroundColor: palette.whitePrimary,
    textAlign: 'center',
  },
});

const LinkTitle = styled('p')({
  color: palette.greenPrimary,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const AvatarWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const ClientName = styled('p')({
  fontSize: '0.75rem',
  color: palette.deepBlueLight,
  [`@media ${bp.md}`]: {
    fontSize: '0.625rem',
  },
});

const LinkContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  maxWidth: 320,
  marginLeft: 16,
});

const StyledAvatar = styled(Avatar)({
  height: '30px',
  width: '30px',
});

const Thumbnail = styled('img')`
  height: 80px;
  object-fit: cover;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
`;

const MetaInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  fontSize: '0.75rem',
  [`@media ${bp.md}`]: {
    fontSize: '0.625rem',
  },
});

const Label = styled(_Label)({
  margin: '4px 8px',
  [`@media ${bp.md}`]: {
    margin: 4,
  },
});

const UpdatedTime = styled('p')({
  color: palette.deepBlueLight,
});
