import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { palette, bp } from 'entity/createTheme';
import { HearingItem } from 'types/HearingSet';
import { useTranslation } from 'react-i18next';
import BulkDownloader from '../CacheablePlayer/BulkDownloader';
import {
  formatTime,
  getImgFromContent,
  getTotalQuestions,
  getVideosFromHearing,
} from 'utility/helpers';
import { Button, Checkbox, Label, NoImageIcon } from 'views/components/primitive';
import { useHistory } from 'react-router-dom';
import { routes } from 'utility/constants';
import { MoreActionsMenu } from 'views/components/compound';
import { getChangeStatusAction } from 'utility/getMoreActionsMenuItems';
import { stringify, parse } from 'qs';
import { useOrganization } from 'organisms/useOrganization';

type Prop = {
  changeTargetHearing: (id: number, status: string) => void;
  isSelectHearing: boolean;
  isSelected: boolean;
  hearing: HearingItem;
  toggleSelectHearingItem: (val: number) => void;
  changeHearingStatus: (status: string, id: number) => void;
  viewDetailHearing: (id: number) => void;
  submittingSaveHearing: boolean;
  isAdmin: boolean;
};

const HearingItemForManagement: FC<Prop> = ({
  changeTargetHearing,
  isSelectHearing,
  hearing,
  isSelected,
  toggleSelectHearingItem,
  viewDetailHearing,
  changeHearingStatus,
  submittingSaveHearing,
  isAdmin,
}) => {
  const { organizationId } = useOrganization();
  const history = useHistory();
  const { t } = useTranslation();

  const videos = getVideosFromHearing(hearing);

  const handleSelect = () => {
    toggleSelectHearingItem(hearing.id);
  };

  const handleChangeStatus = () => {
    const nextStatus = hearing.status === 'published' ? 'draft' : 'published';
    changeHearingStatus(nextStatus, hearing.id);
    changeTargetHearing(hearing.id, nextStatus);
  };

  const handleViewDetail = () => {
    viewDetailHearing(hearing.id);
  };

  const previewHearing = () => {
    const searchParse = parse(history.location.search.slice(1));
    delete searchParse.organization;
    const from = history.location.pathname + stringify(searchParse);
    const pathname = `${routes.management.previewQuestionnaire}/${hearing.id}`;
    const search = `?${stringify({ from })}&organization=${organizationId}`;
    window.open(pathname + search);
  };

  const totalQuestions = useMemo(() => getTotalQuestions(hearing), [hearing]);
  const disablePreview = submittingSaveHearing || totalQuestions <= 0;

  const changeStatusAction = getChangeStatusAction(handleChangeStatus, hearing.status);
  const adminActionMenu = [changeStatusAction];
  const memberActionMenu: {
    label: string;
    handleClick: () => void;
  }[] = [];

  const thumbnail = getImgFromContent(hearing.question_sets[0]?.content);
  return (
    <Root isSelected={isSelected} onClick={handleViewDetail}>
      {videos.length > 0 && (
        <BulkDownloader videos={videos} top={'0px'} right={'0px'} whiteBackground={true} />
      )}
      <HearingInfo>
        <Thumbnail>
          {thumbnail ? (
            <img src={thumbnail} alt="hearing_avatar" loading="lazy" />
          ) : (
            <NoImageIcon size="100%" svgSize={28} />
          )}
        </Thumbnail>

        <div>
          <Title onClick={handleViewDetail} data-autify_selector="hearing_content_title">
            {hearing.title || t('admin.hearingSet.noTitle')}
          </Title>
          <p>{t('hearingSet.hearingItem.numOfVideo', { count: totalQuestions })}</p>
          <MetaInfo>
            <UpdatedDate>
              {t('common.lastUpdated')}: {formatTime(hearing.updated_at || '', 'yyyy/MM/dd')}
            </UpdatedDate>
            <StatusLabel variant="grey" data-autify_selector="hearing_content_status">
              {hearing.status === 'draft' ? t('admin.common.draft') : t('admin.common.publish')}
            </StatusLabel>
          </MetaInfo>
        </div>
      </HearingInfo>
      <ActionArea>
        {isSelectHearing ? (
          <Checkbox checked={isSelected} onChange={handleSelect} />
        ) : (
          <>
            <Button
              label={t('common.preview')}
              size="sm"
              type="default"
              onClick={previewHearing}
              disabled={disablePreview}
            />
            <MoreActionsMenu menuItems={isAdmin ? adminActionMenu : memberActionMenu} />
          </>
        )}
      </ActionArea>
    </Root>
  );
};

export default HearingItemForManagement;

const Root = styled('div')<{ isSelected?: boolean }>(({ isSelected }) => ({
  width: 'calc(50% - 10px)',
  padding: 20,
  marginBottom: 20,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 6,
  background: palette.whitePrimary,
  border: `1px solid ${isSelected ? palette.darkMedium2 : palette.whitePrimary}`,
  cursor: 'pointer',
  position: 'relative',
  boxShadow: `${palette.boxShadow} 0px 1px 9px`,

  '&:before': {
    borderRadius: 6,
    position: 'absolute',
    content: '""',
    display: 'block',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },

  '&:hover': {
    background: palette.grayMedium5,
    border: `1px solid ${isSelected ? palette.darkMedium2 : palette.grayMedium5}`,
    '&:before': {
      background: palette.grayBlur4,
    },
  },

  '&:nth-of-type(odd)': {
    marginRight: 20,
  },

  [`@media ${bp.md}`]: {
    padding: 16,
    marginBottom: 16,
    width: 'calc(50% - 8px)',

    ':nth-of-type(odd)': {
      marginRight: 16,
    },
  },

  [`@media ${bp.xs}`]: {
    width: '100%',
    ':nth-of-type(odd)': {
      marginRight: 0,
    },
  },
}));

const HearingInfo = styled('div')({
  display: 'flex',
  flex: 1,
  marginBottom: 10,
  p: {
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    marginBottom: 10,
    [`@media ${bp.md}`]: {
      marginBottom: 8,
      fontSize: '16px',
    },
  },
  [`@media ${bp.md}`]: {
    marginBottom: 10,
    fontSize: '16px',
  },
});

const Thumbnail = styled('div')({
  height: 88,
  minWidth: 88,
  background: palette.blackPrimary,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 10,
  borderRadius: 6,
  overflow: 'hidden',

  img: {
    height: '100%',
    width: 88,
    objectFit: 'contain',
    [`@media ${bp.md}`]: {
      width: 64,
    },
  },

  [`@media ${bp.md}`]: {
    height: 64,
    minWidth: 64,
  },
});

const Title = styled('p')({
  fontWeight: 'bold',
  marginRight: '30px',
  '@supports not (overflow-wrap: anywhere)': {
    wordBreak: 'break-word',
  },
  '@supports (overflow-wrap: anywhere)': {
    overflowWrap: 'anywhere',
  },
});

const MetaInfo = styled('div')({
  display: 'flex',
  [`@media ${bp.md}`]: {
    flexDirection: 'column',
  },
});

const UpdatedDate = styled('p')({
  color: palette.deepBlueLight,
  fontSize: '0.875rem !important',
});

const StatusLabel = styled(Label)({
  height: 'fit-content',
  width: 'fit-content',
  marginLeft: 6,
  [`@media ${bp.md}`]: {
    marginLeft: 0,
  },
});

const ActionArea = styled('div')({
  display: 'flex',
  marginTop: 10,
  justifyContent: 'flex-end',
  alignItems: 'flex-end',

  [`@media ${bp.md}`]: {
    marginTop: 0,
  },
});
