import { useStore } from 'hooks';
import { useMemo } from 'react';
import { hearingLinkStatus } from 'utility/constants';

const useLinkContent = () => {
  const {
    hearingLinkStore: { combinedPDFStatus, regeneratingPDF, hearingLinkDetail: linkDetail },
  } = useStore();
  const isArchived = linkDetail.status === hearingLinkStatus.archived;

  const { product_contents: productContents, sharing_link_combined_pdf } = linkDetail;
  const hasDraftOrArchived =
    productContents &&
    productContents.some(
      productContent => productContent.status === 'draft' || productContent.status === 'archived'
    );
  const pdfUrl = sharing_link_combined_pdf?.url;
  const disableDownloadPDF = useMemo(() => combinedPDFStatus !== 'done' || regeneratingPDF, [
    combinedPDFStatus,
    regeneratingPDF,
  ]);
  const allContentsArePDF =
    productContents && productContents.every(content => content.content_type === 'pdfs');

  const showDownloadPDFButton = !!sharing_link_combined_pdf && allContentsArePDF;

  const disableTooltip = !disableDownloadPDF || isArchived;
  const disableDownloadButton = disableDownloadPDF || isArchived;

  return {
    hasDraftOrArchived,
    pdfUrl,
    showDownloadPDFButton,
    disableTooltip,
    disableDownloadButton,
  };
};

export default useLinkContent;
