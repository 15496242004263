import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'types/App';
import { ProductListParams } from 'types/HearingLink';
import { ProductItem } from 'types/Product';
import { Drawer, ProductInfo, LoadingIndicator } from 'views/components/compound';
import SearchArea from './SearchArea';
import { typesFromFilterOptions } from '../FileTypeButton';
import styled from '@emotion/styled';
import { bp } from 'entity/createTheme';
import { RadioButton as _RadioButton } from 'views/components/primitive';

type Props = {
  productContentList: ProductItem[];
  open: boolean;
  toggleOpen: () => void;
  getProductContentList: (args: ProductListParams) => void;
  tags?: Tag[];
  isParentComponentOpen: boolean;
  currentFileId: number;
  selectedFileId: number;
  handleSelectFile: (id: number) => void;
  handleSaveFileId: (id: number) => void;
  loading: boolean;
};

const SelectFileDrawer: FC<Props> = ({
  productContentList,
  open,
  toggleOpen,
  getProductContentList,
  tags,
  isParentComponentOpen,
  currentFileId,
  selectedFileId,
  handleSaveFileId,
  handleSelectFile,
  loading,
}) => {
  const { t } = useTranslation();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [currentTagIds, setCurrentTagIds] = useState<number[]>([]);
  const [currentType, setCurrentType] = useState('all');
  const fileTypes = typesFromFilterOptions(currentType);

  useEffect(() => {
    if (!isParentComponentOpen) {
      setSearchKeyword('');
      setCurrentTagIds([]);
      setCurrentType('all');
    } else {
      getProductContentList({ q: searchKeyword, tags: currentTagIds, types: fileTypes });
    }
  }, [isParentComponentOpen]);

  const handleChangeSearchKeyword = (value: string) => {
    if (searchKeyword !== value) {
      setSearchKeyword(value);
    }
  };

  const handleSearch = () => {
    getProductContentList({ q: searchKeyword, tags: currentTagIds, types: fileTypes });
  };

  const onSelectTags = (tags: Tag[]) => {
    if (tags.length > 0 || currentTagIds.length > 0) {
      const tagIds = tags.map(t => t.id);
      setCurrentTagIds(tagIds);
      getProductContentList({ q: searchKeyword, tags: tagIds, types: fileTypes });
    }
  };

  const handleFilter = (typeParams: string) => {
    setCurrentType(typeParams);
    const types = typesFromFilterOptions(typeParams);
    getProductContentList({ q: searchKeyword, tags: currentTagIds, types });
  };

  const handleClose = () => {
    handleSelectFile(currentFileId);
    toggleOpen();
  };

  const handleSelectContents = () => {
    handleSaveFileId(selectedFileId);
    toggleOpen();
  };

  return (
    <Drawer
      disabledAction={!selectedFileId}
      isOpen={open}
      title={t('sharingLink.selectFileModalTitle')}
      onClose={handleClose}
      onSave={handleSelectContents}
      saveButtonLabel={t('common.select2')}
    >
      <DrawerInnerWrapper>
        <SearchArea
          handleSearch={handleSearch}
          searchKeyword={searchKeyword}
          tags={tags}
          handleFilter={handleFilter}
          currentType={currentType}
          currentTagIds={currentTagIds}
          onSelectTags={onSelectTags}
          handleChangeSearchKeyword={handleChangeSearchKeyword}
        />
        {loading ? (
          <LoadingIndicator />
        ) : (
          <ProductContentList className={'scroll-bar-thumbnail'}>
            {productContentList.map(productContent => {
              return (
                <ListItem key={productContent.id}>
                  <RadioButton
                    checked={productContent.id === selectedFileId}
                    onChange={() => handleSelectFile(productContent.id)}
                  />
                  <ProductInfo productContent={productContent} width={96} aspectRatio={4 / 3} />
                </ListItem>
              );
            })}
          </ProductContentList>
        )}
      </DrawerInnerWrapper>
    </Drawer>
  );
};

export default SelectFileDrawer;

const DrawerInnerWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 60px)',
});

const ProductContentList = styled('div')({
  height: '100%',
  overflowY: 'auto',
  marginBottom: 24,
  [`@media ${bp.md}`]: {
    marginBottom: 16,
  },
});

const ListItem = styled('div')({
  marginBottom: 24,
  display: 'flex',
  alignItems: 'center',

  [`@media ${bp.md}`]: {
    marginBottom: 16,
  },
});

const RadioButton = styled(_RadioButton)({
  padding: 0,
  marginRight: 12,
});
