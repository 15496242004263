import styled from '@emotion/styled';
import { Grid, Paper } from '@material-ui/core';
import { palette } from 'entity/createTheme';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../primitive';

type Props = {
  menuName: string;
  buttonText: string;
  settingPageRoute: string;
};

const SettingMenuItem: FC<Props> = ({ menuName, buttonText, settingPageRoute }) => {
  const history = useHistory();

  const goToSettingDetail = () => {
    history.push(settingPageRoute);
  };

  return (
    <Item>
      <Grid container justify="space-between" alignItems="center" spacing={4}>
        <Grid item xs={6}>
          <MenuName>{menuName}</MenuName>
        </Grid>
        <Grid item xs={2}>
          <Button onClick={goToSettingDetail} type="default" size="sm" label={buttonText} />
        </Grid>
      </Grid>
    </Item>
  );
};

export default SettingMenuItem;

const MenuName = styled('p')({
  color: palette.darkMedium2,
  fontWeight: 'bold',
  fontSize: 14,
  paddingLeft: 24,
});

const Item = styled(Paper)({
  boxShadow: `${palette.boxShadow} 0px 1px 9px`,
});
