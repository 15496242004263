import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HearingItem } from 'types/HearingSet';
import { ProductItem } from 'types/Product';
import styled from '@emotion/styled';
import { bp, palette } from 'entity/createTheme';
import { PdfDownloadButton, InstanceTypeCard } from 'views/components/compound';
import { linkContentTypes } from './constants';
import { LinkContentType } from 'types/HearingLink';
import ContentSelector from './ContentSelector';
import { PlaylistItem } from 'types/Playlist';

type Props = {
  clearState(): void;
  linkContentType: LinkContentType;
  toggleProductContentModal: () => void;
  handleChangeLinkType: (contentType: LinkContentType) => void;
  productContent?: ProductItem;
  toggleQuestionnaireModal: () => void;
  questionnaire?: HearingItem;
  playlist?: PlaylistItem;
  togglePlaylistModal: () => void;
};

const LinkContentSelect: FC<Props> = ({
  linkContentType,
  productContent,
  toggleProductContentModal,
  handleChangeLinkType,
  clearState,
  toggleQuestionnaireModal,
  questionnaire,
  playlist,
  togglePlaylistModal,
}) => {
  const { t } = useTranslation();
  const everyContentsPdf = useMemo(
    () => !!productContent && productContent.content_type === 'pdfs',
    [productContent]
  );

  useEffect(() => {
    return () => handleChangeLinkType('product_contents');
  }, []);

  const handleChange = (contentType: LinkContentType) => {
    if (contentType !== linkContentType) {
      handleChangeLinkType(contentType);
      clearState();
    }
  };

  return (
    <SelectHearing>
      <LabelText>{t('sharingLink.linkType')}</LabelText>
      <CardsWrapper>
        {linkContentTypes.map(contentType => (
          <InstanceTypeCard
            key={contentType.id}
            illustrationSrc={contentType.illustrationSrc}
            typeName={t(`${contentType.typeName}.label` as const)}
            selected={contentType.id === linkContentType}
            tooltipText={t(`${contentType.typeName}.tooltip` as const)}
            onClick={() => handleChange(contentType.id)}
          />
        ))}
      </CardsWrapper>
      {everyContentsPdf && (
        <EveryPdfNotice>
          <EveryPdfNoticeText>{t('sharingLink.pdfDownloadWillBeEnabled')}</EveryPdfNoticeText>
          <PdfDownloadButton disableTooltip={true} disableDownloadButton={true} />
        </EveryPdfNotice>
      )}
      <ContentSelector
        linkContentType={linkContentType}
        productContent={productContent}
        questionnaire={questionnaire}
        playlist={playlist}
        toggleProductContentModal={toggleProductContentModal}
        toggleQuestionnaireModal={toggleQuestionnaireModal}
        togglePlaylistModal={togglePlaylistModal}
      />
    </SelectHearing>
  );
};

export default LinkContentSelect;

const SelectHearing = styled('div')({
  paddingTop: 20,
});

const CardsWrapper = styled('div')({
  width: '100%',
  display: 'grid',
  gridGap: 16,
  gridTemplateColumns: '1fr 1fr 1fr',
  [`@media ${bp.md}`]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [`@media ${bp.sm}`]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [`@media ${bp.xs}`]: {
    gridTemplateColumns: '1fr',
  },
  marginBottom: 24,
});

const LabelText = styled('div')({
  fontSize: '0.75rem',
  fontWeight: 700,
  marginBottom: 8,
});

const EveryPdfNotice = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '8px 0px',
  [`@media ${bp.md}`]: {
    flexDirection: 'column',
    padding: '8px 0',
  },
});

const EveryPdfNoticeText = styled('p')({
  marginRight: 10,
  color: palette.darkMedium2,
  fontSize: '12px',
  lineHeight: '34px',
  whiteSpace: 'nowrap',
});
