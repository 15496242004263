import { useOrganization } from 'organisms/useOrganization';
import { useStore } from 'hooks';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Content } from 'types/App';
import { HearingLink, ProductForLink } from 'types/HearingLink';
import { hearingLinkStatus, routes } from 'utility/constants';
import getHearingWebclientPath from 'utility/getHearingWebclientPath';
import { MenuItem } from 'views/components/compound/MoreActionsMenu';

export const useHearingLink = () => {
  const {
    hearingLinkStore: { hearingLinkDetail },
    appStore: {
      userData: { id: userId },
    },
  } = useStore();
  const { id: linkId, session_visit_id } = useParams<{ id: string; session_visit_id: string }>();
  const sessionVisitId = session_visit_id ? parseInt(session_visit_id) : undefined;
  const { user_id } = hearingLinkDetail;
  const isLinkCreator = useMemo(() => user_id?.toString() === userId, [user_id, userId]);
  const { status } = useMemo(() => hearingLinkDetail, [hearingLinkDetail]);
  const isArchived = status === hearingLinkStatus.archived;
  const history = useHistory();
  const handleGoBack = () => history.push(routes.hearingLink);

  return {
    sessionVisitId,
    linkId,
    isLinkCreator,
    isArchived,
    handleGoBack,
  };
};

export const useHearingLinkHeader = () => {
  const { t } = useTranslation();
  const { organizationId } = useOrganization();
  const {
    hearingLinkStore: { hearingLinkDetail, updateStatusHearingLink, restoreHearingLink },
  } = useStore();

  const { linkId, isArchived } = useHearingLink();

  const { status, hash_token } = hearingLinkDetail;

  const linkDetailURL = useMemo(() => {
    const hearingWebclientPath = getHearingWebclientPath(organizationId);
    return `${hearingWebclientPath}${routes.clientStartHearingLink}/${hash_token}`;
  }, [hash_token, organizationId]);

  const [isArchiveModalOpen, setArchiveModalOpen] = useState(false);
  const [isStartTimeSettingDrawerOpen, setStartTimeSettingDrawerOpen] = useState(false);

  //Detect targetVideo to apply start time to
  //It will be: content video of link type product_contents of the first video content of link type playlist / hearing_set
  const targetVideo = useMemo(() => getFirstVideoOfLink(hearingLinkDetail), [hearingLinkDetail]);

  const toggleArchiveModal = () => {
    setArchiveModalOpen(!isArchiveModalOpen);
  };

  const toggleStartTimeSettingDrawer = () =>
    setStartTimeSettingDrawerOpen(!isStartTimeSettingDrawerOpen);

  const handleArchiveHearingLink = () => {
    updateStatusHearingLink(parseInt(linkId), hearingLinkStatus.archived, true);
  };

  const handleRestoreLink = () => {
    restoreHearingLink(parseInt(linkId), true);
  };

  const switchLinkActiveStatus = () => {
    updateStatusHearingLink(parseInt(linkId), status === 'active' ? 'inactive' : 'active', true);
  };

  const moreActionMenu: MenuItem[] = isArchived
    ? [{ label: t('admin.common.restore'), handleClick: handleRestoreLink }]
    : [{ label: t('admin.common.archive'), handleClick: toggleArchiveModal }];

  return {
    handleArchiveHearingLink,
    moreActionMenu,
    switchLinkActiveStatus,
    isArchiveModalOpen,
    toggleArchiveModal,
    linkDetailURL,
    isStartTimeSettingDrawerOpen,
    toggleStartTimeSettingDrawer,
    targetVideo,
  };
};

const getFirstVideoOfLink = (hearingLinkDetail: HearingLink): Content | ProductForLink | null => {
  const { content_type, hearing_set, product_contents, content_playlist } = hearingLinkDetail;
  switch (content_type) {
    case 'hearing_set':
      const questionSetWithVideoContent = hearing_set.question_sets.find(
        qs => qs.content?.content_type === 'videos'
      );
      return !!questionSetWithVideoContent ? questionSetWithVideoContent.content : null;
    case 'product_contents':
      return product_contents.find(content => content.content_type === 'videos') || null;
    case 'playlist':
      return (
        content_playlist?.product_contents.find(content => content.content_type === 'videos') ||
        null
      );
    default:
      return null;
  }
};
