import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import _Button from 'views/components/primitive/Button';
import { SVGIcon, Tooltip } from 'views/components/primitive';
import { palette } from 'entity/createTheme';
import DownloadSVGIcon from 'assets/images/ic_download.svg';

interface PropTypes {
  pdfUrl?: string;
  disableTooltip?: boolean;
  disableDownloadButton?: boolean;
  size?: 'sm' | 'md';
}

const PdfDownloadButton: FC<PropTypes> = ({
  pdfUrl,
  disableTooltip,
  disableDownloadButton,
  size = 'sm',
}) => {
  const { t } = useTranslation();
  return (
    <Tooltip tooltipText={t('sharingLink.pdfDownloadStatus')} disabled={disableTooltip}>
      <PdfDownloadLink
        disabled={!!disableDownloadButton}
        href={pdfUrl && !disableDownloadButton ? pdfUrl : undefined}
        target="_blank"
        rel="noreferrer"
      >
        <Button
          size={size}
          type="default"
          label={
            <>
              <SVGIcon src={DownloadSVGIcon} />
              {t('sharingLink.pdfDownload')}
            </>
          }
          disabled={disableDownloadButton}
        />
      </PdfDownloadLink>
    </Tooltip>
  );
};

export default PdfDownloadButton;

const Button = styled(_Button)<{ disabled?: boolean }>(props => ({
  pointerEvents: 'none',
  whiteSpace: 'nowrap',
  svg: {
    marginRight: 10,
    '> g > g': {
      fill: props.disabled ? palette.whitePrimary : palette.darkMedium2,
    },
  },
}));

const PdfDownloadLink = styled('a')<{ disabled: boolean }>(props => ({
  textDecoration: 'none',
  '&:hover': {
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    [`${PdfDownloadButton}`]: {
      background: palette.grayMedium9,
    },
  },
}));
