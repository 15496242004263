import HttpClient from 'adapter/HTTPClient';

class SettingApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public updateOrganizationLogo = async (data: FormData, organizationId: string) => {
    data.append('organization_id', organizationId);
    await this.httpClient.request({
      method: 'POST',
      url: 'organizations/update_logo',
      data: data,
    });
  };

  public getHubSpotAccounts = async (organizationId: string) => {
    const response = await this.httpClient.request({
      method: 'GET',
      url: 'hubspot/accounts/linked',
      params: {
        organization_id: organizationId,
      },
    });

    return response.data.data.account;
  };

  public postHubSpotAccounts = async (organizationId: string, code: string) => {
    const response = await this.httpClient.request({
      method: 'POST',
      url: 'hubspot/accounts',
      data: {
        organization_id: organizationId,
        code,
      },
    });

    return response.data.data;
  };

  public deleteHubSpotAccounts = async (organizationId: string) => {
    const response = await this.httpClient.request({
      method: 'DELETE',
      url: 'hubspot/accounts/unlink',
      data: {
        organization_id: organizationId,
      },
    });

    return response.data.data;
  };

  public getHubSpotAuthUrl = async (organizationId: string) => {
    const response = await this.httpClient.request({
      method: 'GET',
      url: 'hubspot/settings/auth_url',
      params: {
        organization_id: organizationId,
      },
    });

    return response.data.data.auth_url;
  };
}

export default SettingApi;
