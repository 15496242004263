import HttpClient from 'adapter/HTTPClient';
import { AxiosTransformer } from 'axios';
import { QuestionSetsCreateUpdateAPIBody } from 'types/App';
import { Client as BelfastClient } from '@kaizenplatform/belfast';

class QuestionSetApi {
  private readonly httpClient: HttpClient;
  private readonly belfastClient: BelfastClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
    this.belfastClient = new BelfastClient({
      endpoint: process.env.REACT_APP_KAIZEN_FILES_URL!,
    });
  }

  public createQuestionSet = async ({
    data,
    onUploadProgress,
    transformResponse,
    organization_id,
  }: {
    data: QuestionSetsCreateUpdateAPIBody;
    onUploadProgress: (progressEvent: any) => void;
    transformResponse?: AxiosTransformer | AxiosTransformer[];
    organization_id: string;
  }) => {
    const postBodyData = {
      organization_id,
      hearing_set_id: data.hearing_set_id,
      questions: data.questions,
    };
    const res = await this.httpClient.request({
      method: 'POST',
      url: 'admin/question_sets',
      data: { ...postBodyData },
      headers: { 'content-type': 'application/json' },
      onUploadProgress: onUploadProgress,
      ...(transformResponse && { transformResponse }),
    });

    const updateBodyData: any = {
      organization_id,
      type: data.type,
      questions: data.questions,
    };
    const questionID = res.data.id;
    if (data.creative_search_id) updateBodyData.creative_search_id = data.creative_search_id;
    if (data.thumbnail) updateBodyData.thumbnail = data.thumbnail;
    if (data.url) updateBodyData.url = data.url;

    if (data.file || data.thumbnail_file) {
      const questionBucketID = res.data.content.kaizen_files_bucket_id;
      if (data.file) {
        const belfastDataResponse = await this.belfastClient.uploadFile(
          data.file,
          questionBucketID,
          organization_id,
          {
            onUploadProgress: onUploadProgress,
          },
          true
        );
        updateBodyData.kaizen_files_id = belfastDataResponse.id;
      }
      if (data.thumbnail_file) {
        const belfastResponse = await this.belfastClient.uploadFile(
          data.thumbnail_file,
          questionBucketID,
          organization_id
        );
        updateBodyData.thumbnail_kaizen_files_id = belfastResponse.id;
      }
    }
    await this.httpClient.request({
      method: 'PUT',
      url: `admin/question_sets/${questionID}`,
      data: {
        ...updateBodyData,
      },
    });

    return res;
  };

  public updateQuestionSet = async ({
    id,
    data,
    onUploadProgress,
    transformResponse,
    organization_id,
    bucket_id,
  }: {
    id: string;
    data: QuestionSetsCreateUpdateAPIBody;
    onUploadProgress: (progressEvent: any) => void;
    transformResponse?: AxiosTransformer | AxiosTransformer[];
    organization_id: string;
    bucket_id: string;
  }) => {
    const updateBodyData: any = {
      organization_id,
      type: data.type,
    };
    if (data.creative_search_id) updateBodyData.creative_search_id = data.creative_search_id;
    if (data.thumbnail) updateBodyData.thumbnail = data.thumbnail;
    if (data.url) updateBodyData.url = data.url;
    if (data.questions.length > 0) updateBodyData.questions = data.questions;

    if (data.file) {
      const belfastFileResponse = await this.belfastClient.uploadFile(
        data.file,
        bucket_id,
        organization_id,
        {
          onUploadProgress: onUploadProgress,
        },
        true
      );
      updateBodyData.kaizen_files_id = belfastFileResponse.id;
    }
    if (data.thumbnail_file) {
      const belfastThumbResponse = await this.belfastClient.uploadFile(
        data.thumbnail_file,
        bucket_id,
        organization_id
      );
      updateBodyData.thumbnail_kaizen_files_id = belfastThumbResponse.id;
    }

    const res = await this.httpClient.request({
      method: 'PUT',
      url: `admin/question_sets/${id}`,
      data: { ...updateBodyData },
      headers: { 'content-type': 'application/json' },
      onUploadProgress: onUploadProgress,
      ...(transformResponse && { transformResponse }),
    });

    return res;
  };

  public deleteQuestionSet = async ({
    id,
    organization_id,
  }: {
    id: number;
    organization_id: string;
  }) => {
    await this.httpClient.request({
      method: 'DELETE',
      url: `admin/question_sets/${id}`,
      params: {
        organization_id,
      },
    });
  };
}

export default QuestionSetApi;
