import styled from '@emotion/styled';
import { ICreativeListItem, ICreativeListProps } from '@kaizenplatform/creative-search-ui';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { bp, palette } from 'entity/createTheme';
import { EmbeddingVideoMeta } from 'types/EmbeddingLink';
import { formatTime } from 'utility/helpers';
import { Button, Label as _Label } from 'views/components/primitive';

type Props = {
  setSelectedCreative: Dispatch<SetStateAction<ICreativeListItem | undefined>>;
  handleChangeMeta?: (file: any) => void;
  modalRef?: React.RefObject<HTMLDivElement>;
  handleChangeEmbeddingVideoMeta?: (videoMeta: Partial<EmbeddingVideoMeta>) => Promise<void>;
  needContentTypeLabel?: boolean;
  needSelectButton?: boolean;
};

const getContentTypeString = (creative: ICreativeListItem) => {
  if (creative.contentType.includes('video')) {
    return 'creativeSearch.contentType.video';
  } else if (creative.contentType.includes('image')) {
    return 'creativeSearch.contentType.image';
  } else if (creative.contentType.includes('pdf')) {
    return 'creativeSearch.contentType.pdf';
  } else {
    return 'creativeSearch.contentType.others';
  }
};

export const useCreativeListCardFooter = ({
  setSelectedCreative,
  handleChangeMeta,
  modalRef,
  handleChangeEmbeddingVideoMeta,
  needContentTypeLabel = false,
  needSelectButton = false,
}: Props): { CreativeListCardFooter: ICreativeListProps['cardFooterElement'] } => {
  const CreativeListCardFooter: ICreativeListProps['cardFooterElement'] = creativeListItem => {
    const { t } = useTranslation();
    const contentTypeString = getContentTypeString(creativeListItem);

    return (
      <Wrapper>
        {creativeListItem.fileUpdatedAt && (
          <UpdateDateSpan>
            {t('common.lastUpdated')}:{' '}
            {formatTime(creativeListItem.fileUpdatedAt.toString(), 'yyyy/MM/dd')}
          </UpdateDateSpan>
        )}
        {needContentTypeLabel ? (
          <Label data-autify_selector="product_content_list_content_type_label">
            {t(contentTypeString)}
          </Label>
        ) : (
          <div />
        )}
        {needSelectButton && (
          <Button
            label={t('creativeSearch.select')}
            size="sm"
            type="primary"
            onClick={() => {
              setSelectedCreative(creativeListItem);
              handleChangeMeta &&
                handleChangeMeta({
                  type: 'existing_contents',
                  creativeId: creativeListItem.id,
                  creative_type: creativeListItem.contentType,
                });
              if (modalRef?.current) {
                modalRef?.current.scrollTo(0, 0);
              }
              handleChangeEmbeddingVideoMeta &&
                handleChangeEmbeddingVideoMeta({ creativeId: creativeListItem.id });
            }}
          />
        )}
      </Wrapper>
    );
  };

  return { CreativeListCardFooter };
};

// styled
const Wrapper = styled('div')({
  width: '100%',
  display: 'flex',
  flexFlow: 'column',
});

const Label = styled(_Label)({
  width: 'fit-content',
  height: 'fit-content',
  [`@media ${bp.md}`]: {
    marginLeft: 0,
  },
  color: palette.darkMedium2,
  borderColor: palette.darkMedium2,
});

const UpdateDateSpan = styled('span')({
  marginBottom: '10px',
  fontSize: '0.5rem',
  lineHeight: '1.125rem',
  color: palette.grayMedium4,
});
