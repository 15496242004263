import { ArchivePanel } from 'types/Archive';
import { Routes } from './models';

const keyCodeESC = 27;

const maxSizePdf = 52428800; //50MB
const maxSizePdfClient = 50;
const maxSizeImage = 20971520; //20MB
const maxSizeImageClient = 20;

const thumbnailMaxWidth = 9600;
const thumbnailMaxHeight = 5400;

const routes: Routes = {
  home: '/',
  salesContent: '/sales-contents',
  hearingsPlay: '/hearing-set/play',
  startAppointment: '/hearing-set/play/intro',
  hearingsResult: '/hearing-set/play/result',
  appointment: '/appointments',
  addAppointment: '/quick-hearing/add',
  createAppointment: '/create-appointment',
  styleGuide: '/style-guide',
  hearingLink: '/hearing-links',
  hearingLinkArchive: '/archives',
  detailHearingLink: '/hearing-links/detail',
  clientStartHearingLink: '/hearings',
  salesLog: '/sales-log',
  embeddingLink: '/embedding_link',
  management: {
    main: '/management',
    content: '/management/contents',
    productContent: '/management/product_contents', //old routes, if user access this route, redirect to /management/contents?tab=files
    hearingSet: '/management/hearings', //old routes, if user access this route, redirect to /management/contents?tab=questionnaires
    hearingDetail: '/management/hearings/detail', //old routes, if user access this route, redirect to /management/questionnaires/detail/{id}
    questionnaireDetail: '/management/questionnaires/detail',
    productDetail: '/management/product_contents/detail',
    playlistDetail: '/management/playlists/detail',
    archive: '/management/archives',
    setting: '/management/settings',
    settingLogo: '/management/settings/logo',
    settingHubSpot: '/management/settings/hubspot',
    settingHubSpotCallback: '/management/settings/hubspot/callback',
    previewHearing: '/management/hearings/preview',
    previewQuestionnaire: '/management/questionnaires/preview', //old routes, if user access this route, redirect to /management/questionnaires/preview/{id}
  },
  usingServiceInquiry: '/using_service_inquiry',
  accessLogs: 'access_logs',
};

//Use for label of Select primitive component
export type SelectOptionLabel =
  | 'admin.common.draft'
  | 'admin.common.publish'
  | 'admin.common.checkbox'
  | 'admin.common.radio';
interface Option {
  value: string;
  label: SelectOptionLabel;
}

export type TabOption = {
  id: number;
  value: ArchivePanel;
  count: number;
};

type StatusOption = {
  status: 'draft' | 'published';
  label: SelectOptionLabel;
};

const optionsStatus: StatusOption[] = [
  {
    status: 'draft',
    label: 'admin.common.draft',
  },
  {
    status: 'published',
    label: 'admin.common.publish',
  },
];

const optionsArchive: TabOption[] = [
  {
    id: 0,
    value: 'products',
    count: 0,
  },
  {
    id: 1,
    value: 'hearings',
    count: 0,
  },
  {
    id: 2,
    value: 'playlists',
    count: 0,
  },
  {
    id: 3,
    value: 'links',
    count: 0,
  },
];

const optionsStatusQuestion: Option[] = [
  {
    value: 'checkbox',
    label: 'admin.common.checkbox',
  },
  {
    value: 'radio',
    label: 'admin.common.radio',
  },
];

const acceptVideo = ['.mp4', '.mpg', '.mpeg', '.avi'] as const;
const videoAcceptShow = ['mp4', 'quicktime', 'qtff'];
const acceptThumbnail = ['.jpg', '.jpeg', '.png', '.gif'] as const;
const acceptImage = [...acceptThumbnail, '.bmp'] as const;
const acceptImageMimeTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
const acceptPDF = ['.pdf'] as const;
const acceptPDFMimeTypes = ['pdf'];
const hearingLinkStatus = { active: 'active', inactive: 'inactive', archived: 'archived' };
const acceptVideoImageOrPDF = [...acceptVideo, ...acceptImage, ...acceptPDF] as const;

const otherAnswerFixedId = 53199.5;

export {
  routes,
  keyCodeESC,
  optionsStatus,
  optionsArchive,
  optionsStatusQuestion,
  acceptVideo,
  acceptThumbnail,
  acceptImage,
  acceptImageMimeTypes,
  acceptPDF,
  acceptPDFMimeTypes,
  acceptVideoImageOrPDF,
  videoAcceptShow,
  hearingLinkStatus,
  otherAnswerFixedId,
  maxSizePdf,
  maxSizeImage,
  maxSizePdfClient,
  maxSizeImageClient,
  thumbnailMaxWidth,
  thumbnailMaxHeight,
};
