import React, { FC } from 'react';
import styled from '@emotion/styled';
import { bp } from 'entity/createTheme';
import { ProductItem } from 'types/Product';
import ProductInfo from '../ProductInfo';
import { Checkbox as _Checkbox } from 'views/components/primitive';

type Props = {
  contentList: ProductItem[];
  selectedContentIds: number[];
  toggleSelected: (contentId: number) => void;
};

const ProductContentList: FC<Props> = ({ contentList, selectedContentIds, toggleSelected }) => {
  return (
    <List className={'scroll-bar-thumbnail'}>
      {contentList.map(productContent => {
        const selected = selectedContentIds && selectedContentIds.includes(productContent.id);
        return (
          <ListItem key={productContent.id}>
            <Checkbox checked={selected} onChange={() => toggleSelected(productContent.id)} />
            <ProductInfo productContent={productContent} width={96} aspectRatio={4 / 3} />
          </ListItem>
        );
      })}
    </List>
  );
};

export default ProductContentList;

const List = styled('div')({
  height: '100%',
  overflowY: 'auto',
  marginBottom: 24,
  [`@media ${bp.md}`]: {
    marginBottom: 16,
  },
});

const ListItem = styled('div')({
  marginBottom: 24,
  display: 'flex',
  alignItems: 'center',

  [`@media ${bp.md}`]: {
    marginBottom: 16,
  },
});

const Checkbox = styled(_Checkbox)({
  padding: 0,
  marginRight: 12,
});
