import styled from '@emotion/styled';
import { bp, palette } from 'entity/createTheme';
import useSharableLinkContent from 'organisms/ShareableLink/useSharableLinkContent';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HearingLink } from 'types/HearingLink';
import { formatTime, getImgFromContent } from 'utility/helpers';
import { PdfDownloadButton, ContentCard as _ContentCard } from 'views/components/compound';
import { Divider as _Divider } from 'views/components/primitive';
import ContentItem from './ContentItem';

type Props = {
  linkDetail: HearingLink;
};

const SharableLinkContentList: FC<Props> = ({ linkDetail }) => {
  const {
    hasDraftOrArchived,
    pdfUrl,
    showDownloadPDFButton,
    disableTooltip,
    disableDownloadButton,
  } = useSharableLinkContent();

  const { t } = useTranslation();

  const { product_contents } = linkDetail;

  if (!product_contents || product_contents.length !== 1) return null;

  const { id, status, title, updated_at } = product_contents[0];

  return (
    <ContentCard
      title={t('productContent.title')}
      buttonLabel={''}
      onClickButton={() => {}}
      showActionButton={false}
    >
      {hasDraftOrArchived && (
        <Note>
          <NoteIcon />
          {t('admin.common.isDraftOrArchived')}
        </Note>
      )}
      <ProductListWrapper>
        <ContentItem
          key={id}
          isUnpublic={status !== 'published'}
          src={getImgFromContent(product_contents[0])}
          title={title}
          metaTitle={`${t('productContent.createDate')}: ${formatTime(updated_at, 'yyyy/MM/dd')}`}
        />
      </ProductListWrapper>
      {showDownloadPDFButton && (
        <>
          <Divider />
          <ButtonWrapper>
            <PdfDownloadButton
              size="md"
              pdfUrl={pdfUrl ? pdfUrl : undefined}
              disableTooltip={disableTooltip}
              disableDownloadButton={disableDownloadButton}
            />
          </ButtonWrapper>
        </>
      )}
    </ContentCard>
  );
};

export default SharableLinkContentList;
const ProductListWrapper = styled('div')({});

const ContentCard = styled(_ContentCard)({
  '&>.buttons-group': {
    [`@media ${bp.md}`]: {
      flexDirection: 'column',
    },
  },
});

const Divider = styled(_Divider)({
  margin: '24px 0 16px 0',
});

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingRight: 10,
});

const Note = styled('div')({
  fontSize: 12,
  color: palette.darkMedium2,
  display: 'flex',
  alignItems: 'center',
  marginTop: 5,
});

const NoteIcon = styled('div')({
  height: 10,
  minWidth: 10,
  borderRadius: 10,
  backgroundColor: palette.redPrimary,
  marginRight: 4,
});
