import { CTAItem } from './../../types/HearingLink';
import { HearingLink } from 'types/HearingLink';
import { ListMeta } from 'types/common';

export const defaultHearingLink: HearingLink = {
  id: 0,
  client_id: null,
  client_name: null,
  needs_feedback: true,
  cta: [],
  created_at: '',
  updated_at: '',
  status: '',
  hearing_set: {
    id: 0,
    title: '',
    question_sets: [],
  },
  hearing_set_id: -1,
  product_contents: [],
  sharing_link_combined_pdf: {
    id: 0,
    hash_token: '',
    sharing_link_id: 0,
    status: 'pending',
    updated_at: '',
    created_at: '',
    url: null,
  },
  administrative_memo: '',
  administrative_title: '',
  custom_fields: {
    company_name: false,
    company_pic_name: false,
    email: false,
    phone_number: false,
  },
  content_type: 'product_contents',
};

export const defaultListMeta: ListMeta = {
  total: undefined,
  page: 1,
  sort: 'desc',
  order: 'updated_at',
};

export const defaultCTAItem: CTAItem = {
  title: '',
  link_to: '',
  auto_redirect: false,
};
